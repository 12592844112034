import React, { useEffect, useState } from "react";
import Header from "@components/common/Header/Header";
import examShotRange02 from "../../images/exam_shot_range02.png";
import AppHeader from "@components/common/AppHeader/AppHeader";
import { selectType } from "@store/modules/typeSlice";
import { selectPayerNo } from "@store/modules/payerNoSlice";
import { setFilePathGas } from "@store/modules/meterSlice";
import { useSelector, useDispatch } from "react-redux";
import { checkTime } from "@src/lib/util/Util";
import { BASE_URL, METHOD, comfnApiSvcImgCall } from "@src/lib/common/comfn";
import { LoadingSpinner } from "@src/components/common/LoadingSpinner";
import UAParser from "ua-parser-js";

const MVOT_07 = ({ history }) => {
  const payerInfo = useSelector(selectPayerNo);
  const guides = [
    "빌트인 가스렌지(고정된 가스렌지)입니다.",
    "보조주방에 추가 가스렌지나 가스건조기가 없습니다.",
    "가스렌지 호스 및 밸브를 임의로 철거/연결하지 않겠습니다.",
  ];

  const [checkedInputs, setCheckedInputs] = useState([
    "checkbox0",
    "checkbox1",
  ]);

  useEffect(() => {
    document.title = "삼천리 | 가스렌지 촬영 안내";
    // 현재시간이 22시~6시 사이면 이용불가 페이지
    if (!checkTime()) {
      history.push("/ImpossiblePage");
      return;
    }

    if (payerInfo.payerId === "" || payerInfo.payerId === undefined) {
      history.push("/Error404");
      return;
    }

    // window.history.pushState(null, document.title, window.location.href);
    // window.addEventListener("popstate", () => {
    //   window.history.pushState(null, document.title, window.location.href);
    // });

    window.addEventListener("Camera2Return", (e) => {
      const jsonResult = JSON.parse(e.detail);
      if (jsonResult["rtnCode"] === "0") {
        dispatch(setFilePathGas(jsonResult["filePathGas"]));
        history.push("/VrscMvot/MVIO_04");
      } else {
        alert("오류가 발생하였습니다.");
      }
    });

    window.addEventListener("Camera2ReturnIOS", (e) => {
      //alert(e.detail);
      const jsonResult = JSON.parse(e.detail);
      if (jsonResult["rtnCode"] === "0") {
        dispatch(setFilePathGas(jsonResult["filePathGas"]));
        history.push("/VrscMvot/MVIO_04");
      } else {
        alert("오류가 발생하였습니다.");
      }
    });
  }, []);

  const handleCheckbox = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter((inputId) => inputId !== id));
    }
  };
  const type = useSelector(selectType);
  const dispatch = useDispatch();
  const vrsc = type.vrsc;
  const [capImage, setCapImage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (capImage === "") {
      return;
    } else {
      const formData = new FormData();
      formData.append("reqTy", type.type === "in" ? "MI" : "MO");
      formData.append("imgFile", capImage);
      formData.append("paypNo", payerInfo.payerId);
      fnSendGasImg(formData);
    }
  }, [capImage]);

  const fnSendGasImg = (formData) => {
    const option = {
      serviceId: "sendGasImg",
      baseUrl: BASE_URL.COMMON,
      url: "/img/pg06",
      header: "accessToken",
      method: METHOD.POST,
      data: formData,
      callback: fnCallback,
    };
    setLoading(true);
    comfnApiSvcImgCall(option);
  };

  const fnCallback = (res) => {
    setLoading(false);
    if (res.data.hasOwnProperty("rtnCode")) {
      if (res.data.rtnCode === "-999") {
        return;
      }
    }
    //console.log(res);
    if (res.errorCode < 0) {
      //console.log(res);
    } else if (res.serviceId === "sendGasImg") {
      //console.log(res.data);
      // res.data 에서 filePathGas 리덕스에 저장
      //console.log(res.data.filePathGas);
      dispatch(setFilePathGas(res.data.filePathGas));
      type.type === "in"
        ? history.push("/SelfMvin/MVIO_04")
        : vrsc
        ? history.push("/VrscMvot/MVIO_04")
        : history.push("/SelfMvot/MVIO_04");
    }
  };

  const handleCamera = (e) => {
    
    //alert(navigator.userAgent);
    //앱이 아닐 때 실행 (웹일 때 실행)
    //if (navigator.userAgent.indexOf("wv") === -1) {  //카카오 인앱 브라우저에 wv 문자 있어서 제외함
      if (navigator.userAgent.indexOf("iPhone") === -1) {
        e.preventDefault();
        //alert(e.target.files[0]);
        let file = e.target.files[0];
        //console.log(file);
        setCapImage(file);
      } else if (navigator.userAgent.indexOf("iPhone") > -1) {
        if (
          navigator.userAgent.indexOf("Safari") > -1 ||
          navigator.userAgent.indexOf("KAKAOTALK") > -1
        ) {
          e.preventDefault();
          let file = e.target.files[0];
          //console.log(file);
          setCapImage(file);
        }
      }
    //}
  };

  //가스렌지
  const onClickOk = () => {
    const payerJson2 = {
      paypNo: payerInfo.payerId,
      reqTy: "MO",
    };

    if (navigator.userAgent.indexOf("wv") > -1) {
      // window.set.openCamera(JSON.stringify(payerJson));
      window.set.openCamera2(JSON.stringify(payerJson2));
    } else if (
      navigator.userAgent.indexOf("iPhone") > -1 &&
      navigator.userAgent.indexOf("Safari") === -1
    ) {
      window.webkit.messageHandlers.openCamera2.postMessage(
        JSON.stringify(payerJson2)
      );
    }
  };

  const onClickOkAlert = () => {
    if (
      !checkedInputs.includes("checkbox0") ||
      !checkedInputs.includes("checkbox1") ||
      !checkedInputs.includes("checkbox2")
    ) {
      alert("가스 안전을 위해 모든 항목에 대한 확인/동의가 필요합니다.");
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#f5f5f5" }}>
      {vrsc ? (
        <AppHeader title="가스렌지 촬영 안내" />
      ) : (
        <Header title="가스렌지 촬영 안내" />
      )}

      <div id="container" className="container">
        <div className="tit_small">
          안전한 셀프전출입을 위해 아래 물음에 답해주세요.
        </div>
        {guides.map((guide, index) => {
          return (
            <div key={index}>
              <div className="section_radio right_check">
                <div className="custom-control custom-radio">
                  <input
                    type="checkbox"
                    id={`checkbox${index}`}
                    className="custom-control-input"
                    onChange={handleCheckbox}
                    defaultChecked={index === 2 ? false : true}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`checkbox${index}`}
                  >
                    <div className="info02">
                      {guide.includes("\n")
                        ? guide.split("\n").map((guideSplit, index) => (
                            <span key={index}>
                              {guideSplit}
                              <br />
                            </span>
                          ))
                        : guide}
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })}

        <div
          style={{
            textAlign: "center",
            padding: "15px 0px 15px 0px",
            fontWeight: "bold",
          }}
        >
          <p>아래 예시와 같이 가스렌지 사진을 촬영해주세요.</p>
        </div>
        <div
          className="shot_exam"
          style={{ padding: "20px 0 20px 0", borderRadius: "6px" }}
        >
          <img
            src={examShotRange02}
            alt=""
            style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
          />
        </div>
      </div>

      <label
        className="btn btn_blue btn_bottom mt-5"
        for="cameraFileInput"
        onClick={onClickOkAlert}
      >
        {loading ? <LoadingSpinner /> : "확인"}
        {checkedInputs.includes("checkbox0") &&
        checkedInputs.includes("checkbox1") &&
        checkedInputs.includes("checkbox2") ? (
          <input
            id="cameraFileInput"
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleCamera}
            onClick={onClickOk}
            style={{ display: "none" }}
          />
        ) : (
          ""
        )}
      </label>
    </div>
  );
};

export default MVOT_07;
